import React from 'react';

const LegalCookiesPreference = () => {
 return (
     <>
        <h1>Cookies Preference</h1>
     </>
 )
}

export default LegalCookiesPreference
